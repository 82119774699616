export const PRODUCT_LIST_DATA = [
    {
        name: 'Steel Tower',
        data: [
            {
                title: '',
                img: "images/product/p1.jpg.webp",
                text: [
                    'Telecommunication Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p2.jpg.webp",
                text: [
                    'Galvanized Angular Tubular Iron Telecommunication Steel Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p3.jpg.webp",
                text: [
                    'Monopole Steel Tower,Single Tube Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p4.jpg.webp",
                text: [
                    'Monopole Steel Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p5.jpg.webp",
                text: [
                    'Power Transmission Line Steel Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p6.jpg.webp",
                text: [
                    'Angle Steel Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p7.jpg.webp",
                text: [
                    'Antenna Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p8.jpg.webp",
                text: [
                    'Camouflaged Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p9.jpg.webp",
                text: [
                    'Communication Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p10.jpg.webp",
                text: [
                    'Flange Plate',
                ]
            },
            {
                title: '',
                img: "images/product/p11.jpg.webp",
                text: [
                    'Microwave Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p12.jpg.webp",
                text: [
                    'Monopole Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p13.jpg.webp",
                text: [
                    'Power Distribution Long Span Combined Crossover Steel Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p14.jpg.webp",
                text: [
                    'Steel Pole',
                ]
            },
            {
                title: '',
                img: "images/product/p15.jpg.webp",
                text: [
                    ' Telecommunication Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p16.jpg.webp",
                text: [
                    'Television Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p17.jpg.webp",
                text: [
                    ' Transmission Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p18.jpg.webp",
                text: [
                    'Tubular Tower',
                ]
            },
            {
                title: '',
                img: "images/product/p19.jpg.webp",
                text: [
                    'Welded Steel Pipe',
                ]
            },
        ]
    },
    {
        name: 'Steel Structure',
        data: [
            {
                title: '',
                img: "images/product/ss1.jpg.webp",
                text: [
                    'Power Substation',
                ]
            },
            {
                title: '',
                img: "images/product/ss2.jpg.webp",
                text: [
                    'Power Substation Steel Structure'
                ]
            },
            {
                title: '',
                img: "images/product/ss3.jpg.webp",
                text: [
                    'Steel Plant'
                ]
            }
        ]
    },
    {
        name: 'Flange',
        data: [
            {
                title: '',
                img: "images/product/f1.jpg.webp",
                text: [
                    'Forged Flange',
                ]
            },
            {
                title: '',
                img: "images/product/f2.jpg.webp",
                text: [
                    'Steel Flange',
                ]
            },
        ]
    },
    {
        name: 'Steel Pipe',
        data: [
            {
                title: '',
                img: "images/product/sp1.jpg.webp",
                text: [
                    'Large Diameter Welded Pipe',
                ]
            },
            {
                title: '',
                img: "images/product/sp2.jpg.webp",
                text: [
                    'Longitudinal Welded Pipe',
                ]
            },
        ]
    },
    {
        name: 'Wind Turbine Tower',
        data: [
            {
                title: '',
                img: "images/product/w1.jpg.webp",
                text: [
                    'Wind Tower Shell',
                ]
            },
            {
                title: '',
                img: "images/product/w2.jpg.webp",
                text: [
                    'Wind Turbine Tower',
                ]
            },
        ]
    },
    {
        name: 'High Quality Transmission Tower',
        data: [
            {
                title: '',
                img: "images/product/h1.jpg.webp",
                text: [
                    'High Quality Transmission Tower'
                ]
            }
        ]
    }
]

export const SCREEN_WIDTH = {
    LG: {
        MAX: 99999,
        MIN: 1200
    },
    MD: {
        MAX: 1199,
        MIN: 992,
    },
    SM: {
        MAX: 991,
        MIN: 768
    },
    XS: {
        MAX: 767,
        MIN: 0
    }
}