import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import headerBar from './components/headerBar.vue'
import footerDom from './components/footerDom.vue'

const app = createApp(App)
app.use(router)
app.component('headerBar', headerBar)
app.component('footerDom', footerDom)
app.mount('#app')
