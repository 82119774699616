import { createRouter, createWebHashHistory } from "vue-router";

const homeIndex = () => import("../pages/homeIndex.vue");
const companyProfile = () => import("../pages/companyProfile.vue");
const milestonesPage = () => import("../pages/milestonesPage.vue");
const historyPage = () => import("../pages/historyPage.vue");
const overviewPage = () => import("../pages/overviewPage.vue");
const corporateCulture = () => import("../pages/corporateCulture.vue");
const productsPage = () => import("../pages/productsPage.vue");
const contactUs = () => import("../pages/contactUs.vue");
const projectPage = () => import("../pages/projectPage.vue");
const qualityManagementSystem = () => import("../pages/qualityManagementSystem.vue");
const HealthSafetyEnvironment = () => import("../pages/HealthSafetyEnvironment.vue");
const ResearchPolicy = () => import("../pages/ResearchPolicy.vue");

const routes = [
    {
        path: "/",
        redirect: () => {
            return "homeIndex";
        },
    },
    {
        path: "/homeIndex/",
        name: "homeIndex",
        meta: {
            title: 'homeIndex',
        },
        component: homeIndex,
    },
    {
        path: "/companyProfile/",
        name: "companyProfile",
        meta: {
            title: 'companyProfile',
        },
        component: companyProfile,
    },
    {
        path: "/milestonesPage/",
        name: "milestonesPage",
        meta: {
            title: 'milestonesPage',
        },
        component: milestonesPage,
    },
    {
        path: "/historyPage/",
        name: "historyPage",
        meta: {
            title: 'historyPage',
        },
        component: historyPage,
    },
    {
        path: "/overviewPage/",
        name: "overviewPage",
        meta: {
            title: 'overviewPage',
        },
        component: overviewPage,
    },
    {
        path: "/corporateCulture/",
        name: "corporateCulture",
        meta: {
            title: 'corporateCulture',
        },
        component: corporateCulture,
    },
    {
        path: "/productsPage/",
        name: "productsPage",
        meta: {
            title: 'productsPage',
        },
        component: productsPage,
    },
    {
        path: "/contactUs/",
        name: "contactUs",
        meta: {
            title: 'contactUs',
        },
        component: contactUs,
    },
    {
        path: "/projectPage/",
        name: "projectPage",
        meta: {
            title: 'projectPage',
        },
        component: projectPage,
    },
    {
        path: "/qualityManagementSystem/",
        name: "qualityManagementSystem",
        meta: {
            title: 'qualityManagementSystem',
        },
        component: qualityManagementSystem,
    },
    {
        path: "/HealthSafetyEnvironment/",
        name: "HealthSafetyEnvironment",
        meta: {
            title: 'HealthSafetyEnvironment',
        },
        component: HealthSafetyEnvironment,
    },
    {
        path: "/ResearchPolicy/",
        name: "ResearchPolicy",
        meta: {
            title: 'ResearchPolicy',
        },
        component: ResearchPolicy,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});


export default router;
