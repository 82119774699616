<script setup>
    import { PRODUCT_LIST_DATA } from "@/constants/index"
    import { PRODUCT_MAP } from "@/constants/map"
    import { defineEmits, ref } from 'vue';
    // import { SCREEN_WIDTH } from '@/constants/index'

    const emit = defineEmits(['productClick']);
    const showMenuList = ref(false)
    function productClick(index){
        PRODUCT_MAP.set("product_current_index", index)
        emit("productClick", index);
    }
</script>

<template>
        <div class="headerBar header navbar-fixed-top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <a href="index.html"><img src="../myImages/logo.png" style="width: 242px; padding: 10px;" alt="Interior Design Website Templates Free Download"></a>
                        <!-- <a href="index.html"><img src="../myImages/logo.png" style="width: 242px; padding: 10px;box-shadow: -1px 4px 9px 0 rgb(0 0 0 / 50%);" alt="Interior Design Website Templates Free Download"></a> -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div class="navigation">
                            <div id="navigation">
                                <div id="menu-button" class="visible-xs visible-sm hidden-md hidden-lg" @click="showMenuList = !showMenuList">Menu</div>
                                <!-- pc -->
                                <ul id="menu-list" class="visible-md visible-lg hidden-xs hidden-sm">
                                    <li class="active"><a href="index.html" title="Home">Home</a></li>
                                    <li class="has-sub"><a href="javascript:void(0);" title="About Us">About Us</a>
                                        <img src="images/point_top.svg" alt="">
                                        <ul>
                                            <li><router-link to="/companyProfile">Company Profile</router-link></li>                                            
                                            <li><router-link to="/historyPage">History</router-link></li>                                            
                                            <li><router-link to="/milestonesPage">Milestones</router-link></li>
                                            <li><router-link to="/corporateCulture">Corporate Culture</router-link></li>
                                            <li><router-link to="/overviewPage">Overview</router-link></li>
                                        </ul>
                                    </li>
                                    <li class="has-sub"><a href="javascript:void(0);" title="Product">Product</a>
                                        <img src="images/point_top.svg" alt="">
                                        <ul>
                                            <li v-for="(item, index) in PRODUCT_LIST_DATA" :key="index">
                                                <router-link to="/productsPage" @click="productClick(index)">{{ item.name }}</router-link>
                                            </li>    
                                        </ul>
                                    </li>
                                    <li class="has-sub"><a href="javascript:void(0);" title="Project&commitment">Project&commitment</a>
                                        <img src="images/point_top.svg" alt="">
                                        <ul>
                                            <li><router-link to="/projectPage">Project</router-link></li>              
                                            <li><router-link to="/qualityManagementSystem">Quality management system</router-link></li>              
                                            <li><router-link to="/HealthSafetyEnvironment">Health, Safety & Environment</router-link></li>              
                                            <li><router-link to="/ResearchPolicy">Research policy</router-link></li>   
                                        </ul>
                                    </li>
                                    <li><router-link to="/contactUs">Contact Us</router-link> 
                                    </li>
                                </ul>
                                <!-- phone -->
                                <ul id="menu-list-phone" class="hidden-md hidden-lg" :class="showMenuList ? 'visible-xs visible-sm': 'hidden-xs hidden-sm'">
                                    <li class="active"><a href="index.html" title="Home">Home</a></li>
                                    <li class="has-sub"><a href="javascript:void(0);" title="About Us">About Us</a>
                                        <img src="images/point_top.svg" alt="">
                                        <ul>
                                            <li><router-link to="/companyProfile">Company Profile</router-link></li>                                            
                                            <li><router-link to="/historyPage">History</router-link></li>                                            
                                            <li><router-link to="/milestonesPage">Milestones</router-link></li>
                                            <li><router-link to="/corporateCulture">Corporate Culture</router-link></li>
                                            <li><router-link to="/overviewPage">Overview</router-link></li>
                                        </ul>
                                    </li>
                                    <li class="has-sub"><a href="javascript:void(0);" title="Product">Product</a>
                                        <img src="images/point_top.svg" alt="">
                                        <ul>
                                            <li v-for="(item, index) in PRODUCT_LIST_DATA" :key="index">
                                                <router-link to="/productsPage" @click="productClick(index)">{{ item.name }}</router-link>
                                            </li>    
                                        </ul>
                                    </li>
                                    <li class="has-sub"><a href="javascript:void(0);" title="Project&commitment">Project&commitment</a>
                                        <img src="images/point_top.svg" alt="">
                                        <ul>
                                            <li><router-link to="/projectPage">Project</router-link></li>              
                                            <li><router-link to="/qualityManagementSystem">Quality management system</router-link></li>              
                                            <li><router-link to="/HealthSafetyEnvironment">Health, Safety & Environment</router-link></li>              
                                            <li><router-link to="/ResearchPolicy">Research policy</router-link></li>   
                                        </ul>
                                    </li>
                                    <li><router-link to="/contactUs">Contact Us</router-link> 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<style rel='stylesheet/scss' lang='scss' scoped>
// 最大宽度991，表示在iPad和手机端
@media (max-width: 821px)  {
    #menu-list-phone{
        // background-color: #000;
    }
    .container .row{
        width: 100vw;
    }
    .has-sub ul li{
        background-color: #fff;
    }
    .container{
        width: auto;
    }
}
// .hero-section{
//     background: url(../myImages/header_background.jpg.webp) no-repeat center;
// }
#navigation {
    #menu-button{
        /*display: block; */
        padding: 15px;
        color: #ffffff;
        cursor: pointer;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 20px;
        position: relative;
    }
    #menu-button::before{
        position: absolute;
        top: 21px;
        right: 17px;
        display: block;
        height: 2px;
        width: 20px;
        background: #ffffff;
        content: '';
    }
    #menu-button::after{
        position: absolute;
        top: 16px;
        right: 17px;
        display: block;
        height: 12px;
        width: 20px;
        border-top: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        content: '';
    }
    button{
        background-color: #000;
        color: #fff;
        border: none;
        font-size: 15px;
        font-weight: normal;
        float: right;
        padding: 15px 5px;
    }
    .menu-list{
        // display: block;
    }
    #menu-list-phone{
        li{
            float: unset;
        }
    }
}
#navigation #menu-list-phone>li{
    background-color: #009966;
}
#navigation > ul > li:hover > a,
#navigation > ul > li > a:hover{
    background-color: unset;
    color: #009966;
}
#navigation > #menu-list-phone > .has-sub:hover > a,
#navigation > #menu-list-phone > .has-sub > a:hover,
#navigation > #menu-list-phone > li:hover > a, 
#navigation > #menu-list-phone > li > a:hover{
    background-color: unset;
    color: #fff;
}
#navigation ul ul{
    border-radius: 3px;
    overflow: hidden;
}
#navigation ul ul li a{
    background-color: #fff;
    color: #666666;
    font-weight: normal;
}
.has-sub{
    img{
        position: absolute;
        left: 20px;
        bottom: -7px;
        width: 20px;
        height: 20px;
        display: none;
    }
}
#navigation ul li ul li a:hover{
    color: #009966;
    font-weight: bold;
}
.has-sub:hover {
    img{
        display: block;
    }

}

</style>