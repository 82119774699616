<script setup>
    import { PRODUCT_LIST_DATA } from "@/constants/index"
    import { PRODUCT_MAP } from "@/constants/map"
    import { defineEmits } from 'vue';

    const emit = defineEmits(['productClick']);
    function productClick(index){
        PRODUCT_MAP.set("product_current_index", index)
        emit("productClick", index);
    }
   
</script>

<template>
        <div class="footer">
            <!-- footer-->
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div class="footer-widget">
                            <h3 class="widget-title">About us</h3>
                            <li><router-link to="/companyProfile">Company Profile</router-link></li>                                            
                            <li><router-link to="/historyPage">History</router-link></li>                                            
                            <li><router-link to="/milestonesPage">Milestones</router-link></li>
                            <li><router-link to="/corporateCulture">Corporate Culture</router-link></li>
                            <li><router-link to="/overviewPage">Overview</router-link></li>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div class="footer-widget">
                            <!-- social block -->
                            <h3 class="widget-title">Product</h3>
                            <li v-for="(item, index) in PRODUCT_LIST_DATA" :key="index"><router-link to="/productsPage" @click="productClick(index)">{{ item.name }}</router-link></li>    
                        </div>
                        <!-- /.social block -->
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div class="footer-widget">
                            <!-- social block -->
                            <h3 class="widget-title">Project&commitment</h3>
                            <li><router-link to="/projectPage">Project</router-link></li>              
                            <li><router-link to="/qualityManagementSystem">Quality management system</router-link></li>              
                            <li><router-link to="/HealthSafetyEnvironment">Health, Safety & Environment</router-link></li>              
                            <li><router-link to="/ResearchPolicy">Research policy</router-link></li>              
                        </div>
                        <!-- /.social block -->
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div class="footer-widget">
                            <!-- social block -->
                            <h3 class="widget-title"><router-link to="/contactUs">Contact Us</router-link> </h3>
                        
                        </div>
                        <!-- /.social block -->
                    </div>
                    <!-- <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="footer-widget">
                            <h3 class="widget-title">e-mail Us</h3>
                            <p>info@yourwebsitedomain.com</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="footer-widget">
                            <h3 class="widget-title">Call us</h3>
                            <p>180-874-5234</p>
                            <p>180-752-3957</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
</template>

<style rel='stylesheet/scss' lang='scss' scoped>
.footer-widget a{
    color: #fff;
}
.footer-widget .line{
    text-decoration: underline;
}
.footer{
    background-color: #009966;
}
</style>